import { useEffect, useState } from "react";
import { useAuth } from "@flash-hros/auth-helper";
import {
  getAccessTokenPayloadSync,
  useSelectedCompany,
} from "@flash-tecnologia/hros-web-utility";
import { Checkout } from "../../components/Checkout";
import { trpc } from "../../api/client";
import dispatchToast from "../../utils/dispatchToast";
import { CheckUnleashPermission } from "../../utils/checkUnleashPermission";

interface ICreditCardData {
  name: string;
  documentNumber: string;
  cardNumber: string;
  dueDate: string;
  cvv: string;
}

interface IBillingData {
  emails: string[];
  street: string;
  complement: string;
  zipCode: string;
  state: string;
  district: string;
  city: string;
  number: string;
  phone: string;
}

export const CheckoutPageV2 = () => {
  const [billingData, setBillingData] = useState<IBillingData>();
  const [creditCardData, setCreditCardData] = useState<ICreditCardData>();
  const [paymentMethod, setPaymentMethod] = useState<string>("creditCard");
  const [openSuccessModal, setOpenSuccessModal] = useState<boolean>(false);
  const [selectedSubsidiaryCompanies, setSelectedSubsidiaryCompanies] =
    useState<string[]>([]);
  const [acceptSubsidiaryTerms, setAcceptSubsidiaryTerms] =
    useState<boolean>(false);
  const [financeEmails, setFinanceEmails] = useState<string[]>([]);
  const [errorSubsidiaryTerms, setErrorSubsidiaryTerms] =
    useState<boolean>(false);

  const { selectedCompany } = useSelectedCompany();
  const { economicGroupId } = getAccessTokenPayloadSync();

  const showTermsCheckbox = CheckUnleashPermission({
    economicGroupId,
    flagName: "FLASH_OS_OFFERS_RECOMMENDATION_SECTION",
  });

  const {
    data: dataV2,
    isError: isErrorV2,
    isLoading: isLoadingV2,
  } = trpc.getContractByCompanyV2.useQuery();

  const { data: contractSetup, isLoading: isLoadingSetup } =
    trpc.getContractSetup.useQuery();

  const {
    data: subsidiaryCompanies,
    isError: isErrorCompanies,
    isLoading: isLoaddingCompanies,
  } = trpc.getCompanyAndContract.useQuery();

  if (isErrorV2) {
    dispatchToast({
      type: "error",
      content:
        "Sentimos muito, ocorreu um erro ao buscar os dados de contratação. Tente novamente mais tarde",
    });
  }

  if (isErrorCompanies) {
    dispatchToast({
      type: "error",
      content:
        "Sentimos muito, ocorreu um erro ao buscar as empresas do grupo. Tente novamente mais tarde",
    });
  }
  const findFinanceEmails = trpc.findFinanceEmails.useMutation({
    onSuccess: async (data) => {
      setFinanceEmails(data);
    },
    onError: () =>
      dispatchToast({
        content:
          "Sentimos muito, ocorreu um erro ao buscar os e-mails de cobrança. Tente novamente mais tarde.",
        type: "error",
      }),
  });

  const createSubsidiaryContracts = trpc.createSubsidiaryContract.useMutation({
    onSuccess: async () => ({}),
    onError: () =>
      dispatchToast({
        content:
          "Sentimos muito, ocorreu um erro ao liberar as filiais. Tente novamente mais tarde.",
        type: "error",
      }),
  });

  const updateContractSetup = trpc.updateContractSetup.useMutation({
    onSuccess: async () => {
      if (!showTermsCheckbox)
        signInContract.mutate({ contractId: dataV2?.contract?.id });
      useAuth.setState({ contractStatus: { status: "active", url: "" } });
      setOpenSuccessModal(true);
    },
    onError: () =>
      dispatchToast({
        content: "Sentimos muito, ocorreu um erro. Tente novamente mais tarde",
        type: "error",
      }),
  });

  const createBilletCustomer = trpc.createBilletCustomer.useMutation({
    onSuccess: () => ({}),

    onError: () => {
      dispatchToast({
        content: "Sentimos muito, ocorreu um erro. Tente novamente mais tarde",
        type: "error",
      });
    },
  });

  const validateCreditCard = trpc.validateCreditCard.useMutation({
    onSuccess: () => ({}),

    onError: () => {
      dispatchToast({
        content: "Sentimos muito, ocorreu um erro. Tente novamente mais tarde",
        type: "error",
      });
    },
  });

  const signInContract = trpc.signInContract.useMutation({
    onSuccess: () => ({}),
    onError: () => {
      dispatchToast({
        content: "Sentimos muito, ocorreu um erro. Tente novamente mais tarde",
        type: "error",
      });
    },
  });

  const price = dataV2?.price;
  const template = dataV2?.contractTemplate;
  const companyTier = dataV2?.companyTier;
  const loading =
    isLoadingV2 ||
    isLoadingSetup ||
    validateCreditCard.isLoading ||
    createBilletCustomer.isLoading ||
    updateContractSetup.isLoading ||
    isLoaddingCompanies;
  findFinanceEmails.isLoading;

  const handlePaymentSelection = (companyTier: number | undefined): boolean => {
    if (companyTier) {
      switch (true) {
        case companyTier <= 50:
          return false;
        default:
          return true;
      }
    }
    return false;
  };

  useEffect(() => {
    switch (paymentMethod) {
      case "creditCard":
        if (creditCardData != undefined) {
          const date = creditCardData?.dueDate?.split("/");
          const body = {
            number: creditCardData?.cardNumber?.replace(/\s+/g, ""),
            holder_name: creditCardData?.name,
            exp_month: date?.[0],
            exp_year: date?.[1],
            cvv: creditCardData?.cvv,
            options: { verify_card: true },
            isCompanyAddress: false,
            billing_address: {
              line_1: `${billingData?.number}, ${billingData?.street}, ${billingData?.district}`,
              line_2: `${billingData?.complement}`,
              zip_code: `${billingData?.zipCode?.replace(/[^\d]+/g, "")}`,
              city: `${billingData?.city}`,
              state: `${billingData?.state}`,
              country: "BR",
            },
            phoneNumber: {
              number: `${billingData?.phone
                .split(/[()]/)[2]
                .replace(/[^\d]+/g, "")}`,
              area_code: `${billingData?.phone?.split(/[()]/)[1]}`,
            },
            documentNumber: creditCardData?.documentNumber?.replace(
              /[^\d]+/g,
              ""
            ),
            emails: billingData?.emails ?? [],
          };
          validateCreditCard.mutate({ body });

          if (!!selectedSubsidiaryCompanies?.length && acceptSubsidiaryTerms) {
            createSubsidiaryContracts.mutate({
              subsidiaryCompanies: selectedSubsidiaryCompanies,
              hasActiveOnUnleash: showTermsCheckbox,
            });
          } else if (
            !!selectedSubsidiaryCompanies?.length &&
            !acceptSubsidiaryTerms
          ) {
            dispatchToast({
              type: "error",
              content: "É necessário aceitar os termos para prosseguir.",
            });
            setErrorSubsidiaryTerms(true);
          } else if (
            !selectedSubsidiaryCompanies?.length &&
            acceptSubsidiaryTerms
          ) {
            dispatchToast({
              type: "error",
              content: "Selecione alguma empresa para prosseguir.",
            });
          }

          updateContractSetup.mutate({
            finance: { paymentMethodSelected: "creditCard" },
          });
        }
        break;
      case "billet":
        if (billingData != undefined) {
          if (!!selectedSubsidiaryCompanies?.length && acceptSubsidiaryTerms) {
            createSubsidiaryContracts.mutate({
              subsidiaryCompanies: selectedSubsidiaryCompanies,
              hasActiveOnUnleash: showTermsCheckbox,
            });
          } else if (
            !!selectedSubsidiaryCompanies?.length &&
            !acceptSubsidiaryTerms
          ) {
            dispatchToast({
              type: "error",
              content: "É necessário aceitar os termos para prosseguir.",
            });
            setErrorSubsidiaryTerms(true);

            break;
          }

          const body = {
            isCompanyAddress: false,
            billing_address: {
              line_1: `${billingData?.number}, ${billingData?.street}, ${billingData?.district}`,
              line_2: `${billingData?.complement}`,
              zip_code: `${billingData?.zipCode?.replace(/[^\d]+/g, "")}`,
              city: `${billingData?.city}`,
              state: `${billingData?.state}`,
              country: "BR",
            },
            phoneNumber: {
              number: `${billingData?.phone
                .split(/[()]/)[2]
                .replace(/[^\d]+/g, "")}`,
              area_code: `${billingData?.phone?.split(/[()]/)[1]}`,
            },
            emails: billingData?.emails ?? [],
          };
          createBilletCustomer.mutate({ body });
          updateContractSetup.mutate({
            finance: { paymentMethodSelected: "billet" },
          });
        }
        break;
    }
  }, [billingData, creditCardData, showTermsCheckbox]);

  useEffect(() => {
    findFinanceEmails.mutate();
  }, []);

  return (
    <Checkout
      isSelection={handlePaymentSelection(companyTier)}
      setCreditCardData={setCreditCardData}
      setBillingData={setBillingData}
      setSelectedSubsidiaryCompanies={setSelectedSubsidiaryCompanies}
      selectedSubsidiaryCompanies={selectedSubsidiaryCompanies}
      price={price}
      template={template}
      isPdf={true}
      paymentMethod={paymentMethod}
      setPaymentMethod={setPaymentMethod}
      modules={contractSetup?.modules}
      openSuccessModal={openSuccessModal}
      setOpenSuccessModal={setOpenSuccessModal}
      loading={loading}
      showTermsCheckbox={!showTermsCheckbox}
      subsidiaryCompanies={subsidiaryCompanies}
      companyName={selectedCompany?.name}
      acceptSubsidiaryTerms={acceptSubsidiaryTerms}
      setAcceptSubsidiaryTerms={setAcceptSubsidiaryTerms}
      errorSubsidiaryTerms={errorSubsidiaryTerms}
      setErrorSubsidiaryTerms={setErrorSubsidiaryTerms}
      financeEmails={financeEmails}
      renderSubsidiaryManager={
        contractSetup?.companyType === "main" &&
        subsidiaryCompanies &&
        subsidiaryCompanies?.length > 0
          ? true
          : false
      }
    />
  );
};
