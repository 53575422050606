import {
  Player,
  StyledDiv,
  StyledDivContainer,
  StyledSubText,
  StyledText,
  StyledTitle,
  VideoPlayerContainer,
} from "./styled";
import { convertToEmbedUrl } from "../../../src/utils/convertToEmbedUrl";

const InitialPage = () => {
  return (
    <StyledDivContainer>
      <StyledDiv>
        <StyledTitle variant="headline8">Conheça a Flash People!</StyledTitle>
        <StyledText variant="headline5">
          Em breve, sua rotina ficará mais simples!
        </StyledText>
        <StyledSubText variant="body3">
          Nossas ferramentas ainda não estão disponíveis para sua organização,
          estamos aguardamos a assinatura do contrato. Enquanto isso, descubra
          como nossa solução facilita o dia a dia entre empresa e colaborador!
        </StyledSubText>
        {/* <div>
          <StyledText variant="headline8">
            Fale com um administrador para efetuar a aquisição do produto.
          </StyledText>
        </div> */}
      </StyledDiv>
      <VideoPlayerContainer>
        <Player
          allowFullScreen
          src={convertToEmbedUrl("https://www.youtube.com/watch?v=2QIotoJ4N68")}
        />
      </VideoPlayerContainer>
    </StyledDivContainer>
  );
};

export { InitialPage };
