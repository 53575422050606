import { Dispatch, useEffect, useState } from "react";
import { SearchField, Typography } from "@flash-tecnologia/hros-web-ui-v2";
import { AgreementContainer, Container } from "./styled";
import { SubsidiaryList } from "./components/SubsidiaryList";
import { Warning } from "./components/Warning";
import { segment } from "../../../../utils";
import { CheckboxInternal } from "../../../Checkbox";

export const SubsidiaryManager = ({
  subsidiaryCompanies,
  companyName,
  termsChecked,
  setTermsChecked,
  setSelectedSubsidiaryCompanies,
  selectedSubsidiaryCompanies,
  errorSubsidiaryTerms,
  setErrorSubsidiaryTerms,
  isEnabledOffers,
}: {
  companyName?: string;
  subsidiaryCompanies:
    | {
        id: string;
        legalName: string;
        registrationNumber: string;
        hasContract: boolean;
      }[]
    | undefined;
  termsChecked?: boolean;
  setTermsChecked?: Dispatch<React.SetStateAction<boolean>>;
  setSelectedSubsidiaryCompanies?: Dispatch<React.SetStateAction<string[]>>;
  selectedSubsidiaryCompanies?: string[];
  errorSubsidiaryTerms?: boolean;
  setErrorSubsidiaryTerms?: Dispatch<React.SetStateAction<boolean>>;
  isEnabledOffers?: boolean | undefined;
}) => {
  const [filteredCompanies, setFilteredCompanies] =
    useState(subsidiaryCompanies);

  useEffect(() => {
    setFilteredCompanies(subsidiaryCompanies);
  }, [subsidiaryCompanies]);

  useEffect(() => {
    if (termsChecked) {
      segment({
        track: "people_acquisition_checkout_subsidiaries_checkbox_clicked",
      });
    }
  }, [termsChecked]);

  const handleSearchChange = (value) => {
    setFilteredCompanies(
      subsidiaryCompanies?.filter((comp) =>
        comp?.legalName
          ?.toLocaleLowerCase()
          ?.includes(value?.toLocaleLowerCase())
      )
    );
  };

  return (
    <Container>
      <Typography variant="headline7" weight={700} variantColor="#3B2E37">
        Selecionar empresas
      </Typography>
      <SearchField
        label="Buscar"
        onInputChange={(_, value) => {
          handleSearchChange(value);
        }}
      />
      <SubsidiaryList
        subsidiaryCompanies={filteredCompanies}
        companyName={companyName}
        setSelectedSubsidiaryCompanies={setSelectedSubsidiaryCompanies}
        selectedSubsidiaryCompanies={selectedSubsidiaryCompanies}
      />
      <AgreementContainer>
        <CheckboxInternal
          sx={{ color: errorSubsidiaryTerms ? "#C96C01" : "#83727D" }}
          onChange={() => {
            setTermsChecked?.(!termsChecked);
            setErrorSubsidiaryTerms?.(false);
          }}
          checked={
            termsChecked ||
            subsidiaryCompanies?.every((company) => company?.hasContract)
          }
          disabled={
            (selectedSubsidiaryCompanies &&
              selectedSubsidiaryCompanies?.length <= 0) ||
            subsidiaryCompanies?.every((company) => company?.hasContract)
          }
        />
        <Typography variant="body3" weight={600} variantColor="#83727D">
          Concordo que o contrato desta empresa será replicado para as demais
          empresas selecionadas acima.
        </Typography>
      </AgreementContainer>
      <Warning isEnabledOffers={isEnabledOffers} />
    </Container>
  );
};
