import { ShapeIcon, Typography } from "@flash-tecnologia/hros-web-ui-v2";
import { Container, MainCompanyInfo } from "./styled";
import { BoxAdornment } from "../BoxAdornment";
import { Dispatch, SetStateAction, useState } from "react";

export const SubsidiaryList = ({
  companyName,
  subsidiaryCompanies,
  setSelectedSubsidiaryCompanies,
  selectedSubsidiaryCompanies,
}: {
  companyName?: string;
  subsidiaryCompanies:
    | {
        id: string;
        legalName: string;
        registrationNumber: string;
        hasContract: boolean;
      }[]
    | undefined;
  setSelectedSubsidiaryCompanies?: Dispatch<SetStateAction<string[]>>;
  selectedSubsidiaryCompanies?: string[];
}) => {
  const handleCheckCompany = (id) => {
    if (selectedSubsidiaryCompanies?.includes(id)) {
      setSelectedSubsidiaryCompanies?.(
        selectedSubsidiaryCompanies?.filter((comp) => comp !== id)
      );
    } else {
      setSelectedSubsidiaryCompanies?.([
        ...(selectedSubsidiaryCompanies as [string]),
        id,
      ]);
    }
  };

  return (
    <Container>
      <MainCompanyInfo>
        <ShapeIcon
          name="IconBuildingCommunity"
          variant="default"
          size={32}
          color="#F20D7A"
        />
        <div style={{ display: "flex", flexDirection: "column" }}>
          <Typography variant="headline9" weight={700} variantColor="#FE2B8F">
            {companyName}
          </Typography>
          <Typography variant="caption" variantColor="#83727D">
            Escolha as empresas que farão parte do contrato.
          </Typography>
        </div>
      </MainCompanyInfo>

      {subsidiaryCompanies?.map((company, index) => {
        return (
          <BoxAdornment
            key={index}
            name={company?.legalName}
            registrationNumber={company?.registrationNumber}
            hasContract={company?.hasContract}
            checked={selectedSubsidiaryCompanies?.includes(company?.id)}
            setChecked={() => handleCheckCompany(company?.id)}
          />
        );
      })}
    </Container>
  );
};
