import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { trpc } from "../../api/client";
import { Icons, Skeleton } from "@flash-tecnologia/hros-web-ui-v2";
import { Contract } from "../../../../server/src/utils/types";
import YouTube from "react-youtube";

// redeploy
import {
  StyledButton,
  StyledDiv,
  StyledDivContainer,
  StyledSubText,
  StyledText,
  StyledTitle,
  VideoPlayerContainer,
} from "./styled";

const InitialPage = () => {
  const navigate = useNavigate();
  const [isAdmin, setIsAdmin] = useState<boolean>(false);
  const [hasCanceledContract, setHasCanceledContract] = useState<Contract>();
  const [redirect, setRedirect] = useState(`/people/acquisition/simulation`);

  const { data, isLoading } = trpc.checkIsAdminAndGetContract.useQuery();

  const checkAdmin = data?.isAdmin;
  const contracts = data?.contract;

  const handleClick = () => {
    navigate(redirect);
  };

  useEffect(() => {
    (async () => {
      try {
        if (checkAdmin?.isAdmin) setIsAdmin(checkAdmin?.isAdmin);

        if (contracts) {
          const peopleCanceledContract = contracts?.find((c: any) =>
            c?.product?.some(
              (p: any) =>
                p?.businessUnity == "people" && c?.status == "canceled"
            )
          );
          if (peopleCanceledContract?._id) {
            setHasCanceledContract(
              peopleCanceledContract as unknown as Contract
            );
            setRedirect(`/people/acquisition/new-acquisition/payment`);
          }
        }
      } catch (err) {
        console.log(err);
      }
    })();
  }, [contracts, checkAdmin]);

  return (
    <StyledDivContainer>
      <StyledDiv>
        <StyledTitle variant="headline8">Conheça a Flash People!</StyledTitle>
        <StyledText variant="headline5">
          Impulsione a gestão de pessoas da sua empresa com uma plataforma
          completa e inteligente!
        </StyledText>
        <StyledSubText variant="body3">
          Uma única plataforma com soluções para processos de admissão online,
          treinamentos, desenvolvimento e engajamento dos colaboradores.
          Completa, segura e automatizada para o RH.
        </StyledSubText>
        <div>
          <StyledText variant="headline8">
            Fale com um administrador para efetuar a aquisição do produto.
          </StyledText>
        </div>
      </StyledDiv>
      <VideoPlayerContainer>
        {/* <ReactPlayer
          url={"https://www.youtube.com/watch?v=aYFfH17dgaM"}
          loop={true}
          playing={true}
          playsinline={true}
          muted={true}
          controls
        /> */}

        {/* <VideoPlayer
          options={{
            src: "https://www.youtube.com/watch?v=2g811Eo7K8U",
          }}
        /> */}

        <YouTube
          videoId="aYFfH17dgaM"
          opts={{
            playerVars: {
              autoplay: 1,
            },
          }}
        />
      </VideoPlayerContainer>
    </StyledDivContainer>
  );
};

export { InitialPage };
